import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Switch from 'react-switch';
import ReactCountryFlag from 'react-country-flag';
import './LanguageSwitcher.css';

const LanguageSwitcher = () => {
    const { i18n } = useTranslation();
    const [activeLang, setActiveLang] = useState(i18n.language);

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        setActiveLang(lng);
    };

    useEffect(() => {
        setActiveLang(i18n.language);
    }, [i18n.language]);

    return (
        <div className="switch-container">
            <ReactCountryFlag
                countryCode={activeLang === 'en' ? 'US' : 'SV'}  // Cambia 'ES' por 'SV'
                svg
                style={{
                    width: '24px',
                    height: '24px',
                    marginRight: '8px'
                }}
                title={activeLang === 'en' ? 'United States' : 'El Salvador'}  // Cambia 'Spain' por 'El Salvador'
            />
            <Switch
                onChange={() => changeLanguage(activeLang === 'en' ? 'es' : 'en')}
                checked={activeLang === 'en'}
                offColor="#3498DB"
                onColor="#3498DB"
                uncheckedIcon={false}
                checkedIcon={false}
            />
            <span className="language-label">{activeLang === 'en' ? 'EN' : 'ES'}</span>
        </div>
    );
};

export default LanguageSwitcher;

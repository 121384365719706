import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import PhoneIcon from '@mui/icons-material/Phone';
import './Footer.css';

const Footer = () => {
    const { t } = useTranslation();

    return (
        <Box className="footer">
            <div className="footer-content">
                <div className="footer-section contact">
                    <Typography variant="h6" className="footer-title">{t('footer.contact')}</Typography>
                    <div className="contact-info">
                        <PhoneIcon />
                        <Typography variant="body1" className="footer-text">{t('footer.nationalPhone')}: +503 7250-6774</Typography>
                        <Typography variant="body1" className="footer-text">{t('footer.internationalPhone')}: +503 7250-4696</Typography>
                    </div>
                </div>
                <div className="footer-section hours">
                    <Typography variant="h6" className="footer-title">{t('footer.hours')}</Typography>
                    <Typography variant="body1" className="footer-text">{t('footer.weekdays')}: 8:00 AM - 4:00 PM</Typography>
                    <Typography variant="body1" className="footer-text">{t('footer.saturday')}: {t('footer.closed')}</Typography>
                    <Typography variant="body1" className="footer-text">{t('footer.sunday')}: {t('footer.closed')}</Typography>
                </div>
                <div className="footer-section socialMedia">
                    <Typography variant="h6" className="footer-title">{t('footer.socialMedia')}</Typography>
                    <div className="social-icons">
                        <a href="https://www.facebook.com/profile.php?id=61563965167771&mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer"><FacebookIcon /></a>
                        <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer"><InstagramIcon /></a>
                    </div>
                </div>
            </div>
            <Typography variant="body1" className="footer-copyright">
                &copy; 2024 Diálisis Peritoneal. Todos los derechos reservados.
            </Typography>
        </Box>
    );
};

export default Footer;

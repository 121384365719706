// src/components/WhatsAppButton.js
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FaWhatsapp } from 'react-icons/fa';
import './WhatsAppButton.css';

const WhatsAppButton = () => {
    const { i18n } = useTranslation();
    const phoneNumber = i18n.language === 'en' ? '50372504696' : '50372506774';
    const whatsappLink = `https://wa.me/${phoneNumber}`;

    return (
        <a href={whatsappLink} target="_blank" rel="noopener noreferrer" className="whatsapp-button">
            <FaWhatsapp size={30} color="white" />
        </a>
    );
};

export default WhatsAppButton;

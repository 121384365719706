import React, { useState } from 'react';
import './AboutUs.css';
import { useTranslation } from 'react-i18next';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

const AboutUs = () => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);

    const handleOpen = (item) => {
        setSelectedItem(item);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedItem(null);
    };

    const techItems = [
        {
            title: t('about.techItem1Title'),
            description: t('about.techItem1Description'),
            image: require('./dianeal.jpg')
        },
        {
            title: t('about.techItem2Title'),
            description: t('about.techItem2Description'),
            image: require('./cateter.jpg')
        },
        {
            title: t('about.techItem3Title'),
            description: t('about.techItem3Description'),
            image: require('./linea.jpg')
        },
        {
            title: t('about.techItem4Title'),
            description: t('about.techItem4Description'),
            image: require('./pinzas.jpg')
        },
        {
            title: t('about.techItem5Title'),
            description: t('about.techItem5Description'),
            image: require('./minicap.jpg')
        },
        {
            title: t('about.techItem6Title'),
            description: t('about.techItem6Description'),
            image: require('./Titanium.jpg')
        },
    ];

    return (
        <div className="about-us">
            <div className="mission-vision">
                <div className="mission">
                    <h2>{t('about.missionTitle')}</h2>
                    <p>{t('about.missionText')}</p>
                </div>
                <div className="vision">
                    <h2>{t('about.visionTitle')}</h2>
                    <p>{t('about.visionText')}</p>
                </div>
            </div>

            <div className="team">
                <h2>{t('about.teamTitle')}</h2>
                <div className="team-photo">
                    <img src={require('./equipo clinico.jpeg')} alt={t('about.teamTitle')} className="team-full-image" />
                </div>
            </div>

            <div className="technology">
                <h2>{t('about.techTitle')}</h2>
                <div className="tech-cards">
                    {techItems.map((item, index) => (
                        <div key={index} className="tech-card">
                            <img src={item.image} alt={item.title} className="tech-card-image" />
                            <div className="tech-card-content">
                                <h3>{item.title}</h3>
                                <p>{item.description.substring(0, 100)}...</p>
                                <button className="see-more-button" onClick={() => handleOpen(item)}>Ver más</button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            <Modal open={open} onClose={handleClose} aria-labelledby="modal-title" aria-describedby="modal-description">
                <Box className="modal-box">
                    {selectedItem && (
                        <>
                            <h2 id="modal-title">{selectedItem.title}</h2>
                            <p id="modal-description">{selectedItem.description}</p>
                        </>
                    )}
                </Box>
            </Modal>
        </div>
    );
};

export default AboutUs;

import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Container, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Slider from "react-slick"; // Importación del slider
import Modal from 'react-modal'; // Importar react-modal
import './Home.css';
import image from './image.jpg';
import benefitsImage from './fotonoticia_20210906141452_690.jpg';
import nursingImage from './shutterstock_1805609368-1-1030x689.jpg';
import videoSrc from './Video.mp4';
import { useTranslation } from 'react-i18next';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css"; 
import paciente1 from './paciente1.jpg';
import paciente2 from './paciente2.jpg';
import paciente3 from './paciente3.jpeg';
import paciente4 from './paciente4.jpeg';

// Importaciones de react-icons
import { FaHeartbeat, FaNotesMedical, FaHospitalUser, FaHome, FaStethoscope } from 'react-icons/fa';

// Establecer el elemento root para el modal
Modal.setAppElement('#root');

const Home = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const videoRef = useRef(null);
    const hasInteracted = useRef(false);
    const [expanded, setExpanded] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalContent, setModalContent] = useState({ title: '', content: '' });

    useEffect(() => {
        const handleInteraction = () => {
            hasInteracted.current = true;
        };

        const handleScroll = () => {
            if (!hasInteracted.current) return;
            if (videoRef.current) {
                const videoPosition = videoRef.current.getBoundingClientRect();
                const viewportHeight = window.innerHeight || document.documentElement.clientHeight;

                if (videoPosition.top < viewportHeight && videoPosition.bottom > 0) {
                    videoRef.current.play();
                } else {
                    videoRef.current.pause();
                }
            }
        };

        document.addEventListener('click', handleInteraction);
        window.addEventListener('scroll', handleScroll);

        return () => {
            document.removeEventListener('click', handleInteraction);
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        const scrollToFaq = localStorage.getItem('scrollToFaq');
        if (scrollToFaq === 'true') {
            const faqElement = document.getElementById('faq');
            if (faqElement) {
                faqElement.scrollIntoView({ behavior: 'smooth' });
            }
            localStorage.removeItem('scrollToFaq');
        }
    }, []);

    const handleButtonClick = () => {
        navigate('/services');
    };

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const openModal = (content) => {
        setModalContent(content);
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    const formatContent = (content) => {
        if (Array.isArray(content)) {
            return (
                <div>
                    <p>{t("home.benefit1Detail")}</p>
                    <ul>
                        {content.map((item, index) => (
                            <li key={index}>{item}</li>
                        ))}
                    </ul>
                </div>
            );
        }
        return <p>{content}</p>;
    };

    const benefits = [
        {
            icon: <FaHeartbeat />,
            text: t("home.benefit1"),
            content: {
                title: t("home.benefit1"),
                content: formatContent(t("home.benefit1DetailPoints", { returnObjects: true }))
            }
        },
        {
            icon: <FaNotesMedical />,
            text: t("home.benefit2"),
            content: {
                title: t("home.benefit2"),
                content: formatContent(t("home.benefit2DetailPoints", { returnObjects: true }))
            }
        },
        {
            icon: <FaHospitalUser />,
            text: t("home.benefit3"),
            content: {
                title: t("home.benefit3"),
                content: formatContent(t("home.benefit3Detail"))
            }
        },
        {
            icon: <FaHome />,
            text: t("home.benefit4"),
            content: {
                title: t("home.benefit4"),
                content: formatContent(t("home.benefit4DetailPoints", { returnObjects: true }))
            }
        },
        {
            icon: <FaStethoscope />,
            text: t("home.benefit5"),
            content: {
                title: t("home.benefit5"),
                content: formatContent(t("home.benefit5Detail"))
            }
        }
    ];

    const testimonials = [
        { comment: t("home.testimonial1"), stars: 5, image: paciente1 },
        { comment: t("home.testimonial2"), stars: 5, image: paciente2 },
        { comment: t("home.testimonial3"), stars: 5, image: paciente3 },
        { comment: t("home.testimonial4"), stars: 5, image: paciente4 }
    ];

    const faqs = [
        { question: t("home.faq1Question"), answer: t("home.faq1Answer") },
        { question: t("home.faq2Question"), answer: t("home.faq2Answer") },
        { question: t("home.faq3Question"), answer: t("home.faq3Answer") },
        { question: t("home.faq4Question"), answer: t("home.faq4Answer") },
        { question: t("home.faq5Question"), answer: t("home.faq5Answer") }
    ];

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <div className="home">
        <div className="hero">
    <picture>
        <source media="(max-width: 768px)" srcSet={require('./portadatelefono.jpg')} />
        <img src={image} alt="Diálisis Peritoneal" className="hero-image" />
    </picture>
    <div className="hero-text">
        <Typography variant="h2" className="hero-title">{t('home.heroTitle')}</Typography>
        <Button 
            variant="contained" 
            color="primary" 
            className="hero-button" 
            onClick={handleButtonClick}
        >
            {t('home.heroButton')}
        </Button>
    </div>
</div>

            <Container>
                <section className="procedure-video">
                    <Typography variant="h3" className="section-title">{t('home.procedureVideoTitle')}</Typography>
                    <div className="video-container">
                        <video ref={videoRef} className="procedure-video-player" controls>
                            <source src={videoSrc} type="video/mp4" />
                            {t('home.videoFallback')}
                        </video>
                    </div>
                </section>
                <section className="benefits">
                    <Typography variant="h3" className="section-title">{t('home.benefitsTitle')}</Typography>
                    <div className="benefits-container">
                        <div className="benefits-text">
                            {benefits.map((benefit, index) => (
                                <div key={index} className="benefit-item" onClick={() => openModal(benefit.content)}>
                                    <div className="benefit-icon">{benefit.icon}</div>
                                    <Typography variant="h6" className="benefit-text">{benefit.text}</Typography>
                                </div>
                            ))}
                        </div>
                        <div className="benefits-image">
                            <img src={benefitsImage} alt="Benefits" />
                        </div>
                    </div>
                </section>
                <section className="nursing">
                    <Typography variant="h3" className="section-title">{t('home.nursingTitle')}</Typography>
                    <div className="nursing-container">
                        <div className="nursing-image">
                            <img src={nursingImage} alt="Nursing Role" />
                        </div>
                        <div className="nursing-text">
                            <Typography variant="body1">
                                {t('home.nursingText')}
                            </Typography>
                        </div>
                    </div>
                </section>
                <section className="testimonials">
                    <Typography variant="h3" className="section-title testimonials-title">{t('home.testimonialsTitle')}</Typography>
                    <Slider {...settings}>
                        {testimonials.map((testimonial, index) => (
                            <div key={index} className="testimonial-item">
                                <img src={testimonial.image} alt={`Paciente ${index + 1}`} className="testimonial-image" />
                                <Typography variant="body1" className="testimonial-comment">"{testimonial.comment}"</Typography>
                                <div className="testimonial-stars">
                                    {'★'.repeat(testimonial.stars)}{'☆'.repeat(5 - testimonial.stars)}
                                </div>
                            </div>
                        ))}
                    </Slider>
                </section>
                <section id="faq" className="faq">
                    <Typography variant="h3" className="section-title">{t('home.faqTitle')}</Typography>
                    <div className="faq-grid">
                        <div className="faq-column">
                            {faqs.slice(0, 3).map((faq, index) => (
                                <Accordion 
                                    key={index}
                                    expanded={expanded === index}
                                    onChange={handleChange(index)}
                                    className="faq-item"
                                >
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography variant="h6">{t(faq.question)}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography variant="body1">{t(faq.answer)}</Typography>
                                    </AccordionDetails>
                                </Accordion>
                            ))}
                        </div>
                        <div className="faq-column">
                            {faqs.slice(3).map((faq, index) => (
                                <Accordion 
                                    key={index + 3}
                                    expanded={expanded === index + 3}
                                    onChange={handleChange(index + 3)}
                                    className="faq-item"
                                >
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography variant="h6">{t(faq.question)}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography variant="body1">{t(faq.answer)}</Typography>
                                    </AccordionDetails>
                                </Accordion>
                            ))}
                        </div>
                    </div>
                </section>
            </Container>
            {/* Modal */}
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Benefit Details"
                closeTimeoutMS={500} // Tiempo de transición al cerrar
                className="modal-content"
                overlayClassName="modal-overlay"
            >
                <h2>{modalContent.title}</h2>
                <ul>{modalContent.content}</ul>
                <button onClick={closeModal} style={{ marginTop: '20px', padding: '10px 20px', backgroundColor: '#3498DB', color: 'white', border: 'none', borderRadius: '5px', cursor: 'pointer' }}>Cerrar</button>
            </Modal>
        </div>
    );
};

export default Home;

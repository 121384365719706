import React from 'react';
import { Container, Grid, Card, CardContent, Typography, CardActions, Button, Box } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import RoomServiceIcon from '@mui/icons-material/RoomService';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import HomeIcon from '@mui/icons-material/Home';
import PublicIcon from '@mui/icons-material/Public';
import ElderlyIcon from '@mui/icons-material/Elderly';
import { useTranslation } from 'react-i18next';
import './Services.css';

// Importación de imágenes
import foreignServiceImage from './taxi-driver-assisting-passengers-in-wheelchairs-foreground-focus-silhouetted-against-an-airport-te.jpeg';
import homeDialysisImage from './Default_A_warm_and_intimate_photograph_of_a_compassionate_nurs_1.jpg';
import materialServiceImage from './close-up-of-a-blue-uniformed-nurse-jotting-down-details-in-a-notebook-elderly-individual-in-an-oche.jpeg';
import clinicServiceImage from './hospital-scene-nurse-seated-adjacent-to-a-patient-in-a-clinical-bed-immaculate-white-walls-envelop (1).jpeg';
import elderCareServiceImage from './nurse-opening-front-door-to-embrace-elderly-mother-anticipation-reflected-in-their-expressions-set (1).jpeg';

const Services = () => {
    const { t } = useTranslation();

    const servicesData = [
        {
            title: t('services.foreignService'),
            icon: <PublicIcon style={{ fontSize: 40, color: '#3498DB' }} />,
            description: t('services.foreignServiceDescription', { returnObjects: true }),
            image: foreignServiceImage,
            whatsappLink: 'https://wa.me/50372504696?text=Me%20interesa%20el%20Servicio%20para%20extranjeros'
        },
        {
            title: t('services.homeDialysisService'),
            icon: <HomeIcon style={{ fontSize: 40, color: '#3498DB' }} />,
            description: t('services.homeDialysisServiceDescription', { returnObjects: true }),
            image: homeDialysisImage,
            whatsappLink: 'https://wa.me/50372506774?text=Me%20interesa%20el%20Servicio%20de%20diálisis%20en%20domicilio%20del%20paciente'
        },
        {
            title: t('services.materialService'),
            icon: <RoomServiceIcon style={{ fontSize: 40, color: '#3498DB' }} />,
            description: t('services.materialServiceDescription', { returnObjects: true }),
            image: materialServiceImage,
            whatsappLink: 'https://wa.me/50372506774?text=Me%20interesa%20el%20Servicio%20de%20diálisis%20con%20material%20pero%20no%20tiene%20quien%20la%20dialice%20en%20casa'
        },
        {
            title: t('services.clinicService'),
            icon: <LocalHospitalIcon style={{ fontSize: 40, color: '#3498DB' }} />,
            description: t('services.clinicServiceDescription', { returnObjects: true }),
            image: clinicServiceImage,
            whatsappLink: 'https://wa.me/50372506774?text=Me%20interesa%20el%20Servicio%20de%20diálisis%20en%20clínica'
        },
        {
            title: t('services.elderCareService'),
            icon: <ElderlyIcon style={{ fontSize: 40, color: '#3498DB' }} />,
            description: t('services.elderCareServiceDescription', { returnObjects: true }),
            image: elderCareServiceImage,
            whatsappLink: 'https://wa.me/50372506774?text=Me%20interesa%20el%20Servicio%20de%20cuidados%20del%20adulto%20mayor%20en%20el%20hogar%20del%20paciente'
        }
    ];

    return (
        <Container className="services-container">
            <Typography variant="h2" className="services-title">{t('services.title')}</Typography>
            <div className="services-divider"></div>
            <Grid container spacing={4}>
                {servicesData.map((service, index) => (
                    <Grid item xs={12} md={6} key={index}>
                        <Card className="service-card">
                            <img src={service.image} alt={service.title} className="service-image" />
                            <CardContent>
                                <Box className="service-icon">
                                    {service.icon}
                                </Box>
                                <Typography variant="h5" component="div">
                                    {service.title}
                                </Typography>
                                <Box className="service-divider"></Box>
                                <ul>
                                    {service.description.map((desc, idx) => (
                                        <li key={idx}>{desc}</li>
                                    ))}
                                </ul>
                            </CardContent>
                            <CardActions>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    startIcon={<WhatsAppIcon />}
                                    href={service.whatsappLink}
                                    target="_blank"
                                >
                                    {t('services.button')}
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Container>
    );
};

export default Services;

import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import Services from './pages/Services';
import AboutUs from './pages/AboutUs';
import WhatsAppButton from './pages/WhatsAppButton'; // Importa el botón de WhatsApp
import './styles.css';
import './transitions.css';

const App = () => {
    const location = useLocation();

    return (
        <div className="app-container">
            <Header />
            <div className="main-content">
                <SwitchTransition>
                    <CSSTransition
                        key={location.key}
                        classNames="fade"
                        timeout={300}
                    >
                        <Routes location={location}>
                            <Route path="/" element={<Home />} />
                            <Route path="/services" element={<Services />} />
                            <Route path="/about" element={<AboutUs />} />
                        </Routes>
                    </CSSTransition>
                </SwitchTransition>
            </div>
            <WhatsAppButton /> {/* Añade el botón de WhatsApp aquí */}
            <Footer />
        </div>
    );
};

const AppWrapper = () => (
    <Router>
        <App />
    </Router>
);

export default AppWrapper;

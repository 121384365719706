import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, IconButton, Drawer, List, ListItem, ListItemText } from '@mui/material';
import { useNavigate, Link } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import { useTranslation } from 'react-i18next';
import './Header.css';
import logo from '../assets/logo.png';
import LanguageSwitcher from './LanguageSwitcher';

const Header = () => {
    const { t } = useTranslation();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const navigate = useNavigate();

    const handleFaqClick = () => {
        localStorage.setItem('scrollToFaq', 'true');
        navigate('/');
    };

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setDrawerOpen(open);
    };

    const list = () => (
        <div
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
        >
            <List>
                <ListItem button component={Link} to="/">
                    <ListItemText primary={t('header.home')} />
                </ListItem>
                <ListItem button component={Link} to="/services">
                    <ListItemText primary={t('header.services')} />
                </ListItem>
                <ListItem button onClick={handleFaqClick}>
                    <ListItemText primary={t('header.faq')} />
                </ListItem>
                <ListItem button component={Link} to="/about">
                    <ListItemText primary={t('header.about')} />
                </ListItem>
                <ListItem>
                    <LanguageSwitcher />
                </ListItem>
            </List>
        </div>
    );

    return (
        <>
            <AppBar position="static" color="primary">
                <Toolbar>
                    <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleDrawer(true)}>
                        <MenuIcon />
                    </IconButton>
                    <img src={logo} alt="Diálisis Peritoneal" className="logo" />
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        Diálisis Peritoneal
                    </Typography>
                    <div className="nav-links">
                        <Link to="/" className="nav-link">{t('header.home')}</Link>
                        <Link to="/services" className="nav-link">{t('header.services')}</Link>
                        <span onClick={handleFaqClick} className="nav-link" style={{ cursor: 'pointer' }}>
                            {t('header.faq')}
                        </span>
                        <Link to="/about" className="nav-link">{t('header.about')}</Link>
                        <LanguageSwitcher />
                    </div>
                </Toolbar>
            </AppBar>
            <Drawer
                anchor="left"
                open={drawerOpen}
                onClose={toggleDrawer(false)}
            >
                {list()}
            </Drawer>
        </>
    );
};

export default Header;

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

const resources = {
    en: {
        translation: {
            header: {
                home: "Home",
                services: "Services",
                faq: "FAQ",
                about: "About Us"
            },
            home: {
                heroTitle: "PERITONEAL DIALYSIS",
                heroButton: "LEARN ABOUT OUR SERVICES",
                procedureVideoTitle: "Peritoneal Dialysis Procedure",
                benefitsTitle: "Benefits of Peritoneal Dialysis",
                testimonialsTitle: "Patient Testimonials",
                benefit1: "Improves Quality of Life",
                benefit2: "Greater Treatment Flexibility",
                benefit3: "Personalized Treatment",
                benefit4: "Reduces Hospitalizations",
                benefit5: "Easy Home Management",
                nursingTitle: "Nursing Role",
                nursingText: "The nursing team in peritoneal dialysis plays a crucial and multidisciplinary role in patient care management. They work hard every day to provide comprehensive care, specializing in peritoneal dialysis. They manage procedures, including connection and cleaning protocols, preventing infectious complications. They also have knowledge in renal diet and comprehensive care.",
                benefit1Detail: "The peritoneal dialysis (PD) process reduces and removes excess salt, waste products, and water so they do not build up inside your body, also helping with other factors such as:",
                benefit1DetailPoints: [
                    "Maintains safe levels of minerals and vitamins.",
                    "Helps control blood pressure.",
                    "Prevents infections."
                ],
                benefit2Detail: "The lack of dependency on hospitalization and specific medical equipment provides the option to:",
                benefit2DetailPoints: [
                    "Adapt to the patient's needs.",
                    "Promotes well-being which helps in returning to day-to-day activities.",
                    "More time with family, friends, and acquaintances.",
                    "Time for oneself and personal or work activities."
                ],
                benefit3Detail: "The PD procedure has the ability to be adapted to all needs and individualized based on circumstances. These circumstances mainly include the lifestyle and personal needs of each individual. At PDH, we optimize the treatment according to what the user requests.",
                benefit4Detail: "A dependency on hospitalization for peritoneal dialysis patients can lead to:",
                benefit4DetailPoints: [
                    "Increased infection rates at the exit site.",
                    "Increased number of infections per year per patient.",
                    "Demotivation of patients to continue with the PD procedure.",
                    "Our commitment to the patient is to reduce the number of visits by promoting PD at home."
                ],
                benefit5Detail: "The home is a very important place for the development of a person's basic activities as it is where they can feel good, calm, protected, and safe. The primary goal of PD is for the patient to be able to request treatment to be done at home. One of the key points in the process is that it adapts to the needs and/or activities that the patient requires, thus promoting well-being and comfort.",
                testimonial1: "Excellent service and care. They have significantly helped improve my quality of life.",
                testimonial2: "Very professional and attentive. I highly recommend their services.",
                testimonial3: "Highly recommended. The staff is very skilled and friendly.",
                testimonial4: "Thanks to their service, I've been able to manage my treatment from home without any issues.",
                testimonial5: "The best dialysis service I've received. They are always looking out for my well-being.",
                videoFallback: "Your browser does not support the video element.",
                faqTitle: "Frequently Asked Questions",
                faq1Question: "If I don't have anyone to dialyze me at home, what should I do?",
                faq1Answer: "Contact our clinical advisors to provide assistance at the provided contact numbers.",
                faq2Question: "If I don't have a catheter, where should I go?",
                faq2Answer: "Contact our clinical advisors for all the necessary information.",
                faq3Question: "I'm a traveler, I already have a dialysis catheter, can I get the treatment and a nurse in El Salvador?",
                faq3Answer: "We are happy to provide everything needed to perform the treatment. We have certified personnel.",
                faq4Question: "I live far away, I have materials at home but my caregiver can't dialyze me, can I get a nurse to do it?",
                faq4Answer: "We are happy to come to your home to perform procedures with proper protocols.",
                faq5Question: "I'm a traveler and don't have someone to pick me up from the airport for dialysis.",
                faq5Answer: "We can pick you up from the airport, provide transportation and treatment, and offer care at your place of stay with specialized clinical staff."
            },
            services: {
                title: "Our Services",
                button: "Learn More",
                foreignService: "Service for Foreigners",
                homeDialysisService: "Home Dialysis Service",
                materialService: "Dialysis Service with Material but No Nurse",
                clinicService: "Clinic Dialysis Service",
                elderCareService: "Elder Care at Patient's Home",
                foreignServiceDescription: [
                    "Transportation to and from the airport",
                    "Certified nurse",
                    "Complete dialysis material",
                    "Full treatment service",
                    "Flexible scheduling for the patient"
                ],
                homeDialysisServiceDescription: [
                    "If the patient does not have material",
                    "A nurse is assigned",
                    "Materials are provided",
                    "Care is provided"
                ],
                materialServiceDescription: [
                    "Certified nurse to perform treatment",
                    "Flexible dialysis schedule",
                    "Dialysis at the patient's home"
                ],
                clinicServiceDescription: [
                    "Nurse certified in peritoneal dialysis",
                    "Therapy supplies",
                    "Blood pressure control",
                    "Fluid management",
                    "Diet management",
                    "Vital signs monitoring",
                    "Therapy according to patient's time and need"
                ],
                elderCareServiceDescription: [
                    "Elder care provided",
                    "Medication assistance",
                    "Assistance for all outings",
                    "Vehicle provided"
                ]
            },
            footer: {
                contact: "Contact",
                nationalPhone: "National Phone",
                internationalPhone: "International Phone",
                hours: "Office Hours",
                weekdays: "Monday to Friday",
                saturday: "Saturday",
                sunday: "Sunday",
                closed: "Closed",
                socialMedia: "Follow Us",
                copyright: "Peritoneal Dialysis. All rights reserved."
            },
            about: {
                missionTitle: "Mission",
                missionText: "Perform quality dialysis treatment with trained and certified equipment in treatment from minimal care to the care of renal patients.",
                visionTitle: "Vision",
                visionText: "Provide dialysis and care to both national and foreign patients by applying protocols that ensure safety in treatment with specialized nursing and medical personnel.",
                teamTitle: "The Medical Team",
                techTitle: "Technologies and Treatments",
                techItem1Title: "Dianeal Bag",
                techItem1Description: "A Dianeal bag contains a solution that retains the fluids normally removed by the kidneys. The kidneys are responsible for removing excess fluids, minerals, and waste products from the body. However, when kidney failure occurs, these waste products and other fluids can accumulate in the body, leading to high blood pressure and a decrease in red blood cells. A dialysis bag can be used as a substitute for a kidney during kidney failure or after a kidney transplant to allow for a productive lifestyle.",
                techItem2Title: "Tenckhoff Catheter with Pig Tail",
                techItem2Description: "A Tenckhoff catheter is a thin, flexible tube with an end coiled like a pig's tail, through which dialysis fluid is introduced into the body and then removed. The tissue covering most of the organs in the abdomen is called the peritoneum and acts as a filter in this PD process.",
                techItem3Title: "Long-Life Short Transfer Line",
                techItem3Description: "A transfer set with a disconnection system that includes a female connector, an integrated safety clamp, tubing, and a male Luer Lock connector with a double seal that connects to a titanium connector. This connector has a lifespan of approximately 6 months and allows the connection between the Dianeal bag and the Minicap plug.",
                techItem4Title: "Clamping Pliers",
                techItem4Description: "BAXTER clamping pliers are designed solely to obstruct the drainage, infusion, and occlusion lines when needed without cutting or tying any line.",
                techItem5Title: "Minicap Plug",
                techItem5Description: "A protective plastic plug containing a povidone-iodine-moistened sponge inside to prevent direct contamination during patient connection. Its effectiveness is achieved by providing the necessary dose of disinfectant for each DP bag change.",
                techItem6Title: "Titanium Connector",
                techItem6Description: "A device necessary to adjust the end of the Tenckhoff catheter and the transfer line. Its main characteristics include the ability to be sterilized, the ability to withstand manipulations in aseptic techniques, reusability if necessary, freedom from pathogens, and its primary function being non-toxic and antioxidant, providing well-being to the PD patient."
            }
        }
    },
    es: {
        translation: {
            header: {
                home: "Inicio",
                services: "Servicios",
                faq: "Preguntas Frecuentes",
                about: "Sobre Nosotros"
            },
            home: {
                heroTitle: "DIÁLISIS PERITONEAL",
                heroButton: "CONOCE NUESTROS SERVICIOS",
                procedureVideoTitle: "Procedimiento de Diálisis Peritoneal",
                benefitsTitle: "Beneficios de la Diálisis Peritoneal",
                testimonialsTitle: "Testimonios de Pacientes",
                benefit1: "Mejora la calidad de vida",
                benefit2: "Mayor flexibilidad en el tratamiento",
                benefit3: "Tratamiento personalizado",
                benefit4: "Reducción de hospitalizaciones",
                benefit5: "Fácil manejo en casa",
                nursingTitle: "Papel de la Enfermería",
                nursingText: "El equipo de enfermería en diálisis peritoneal juega un papel crucial y multidisciplinario en la gestión del cuidado del paciente. Trabajan arduamente todos los días para brindar una atención integral, especializándose en diálisis peritoneal. Manejan protocolos de procedimientos, incluyendo la conexión y la limpieza, previniendo complicaciones infecciosas. También tienen conocimiento en dieta renal y cuidados integrales.",
                benefit1Detail: "El proceso de diálisis peritoneal (DP) reduce y retira el excedente de sal, productos de desecho y agua para que no se acumulen dentro de su cuerpo además ayudando en otros factores como:",
                benefit1DetailPoints: [
                    "Mantiene niveles seguros de minerales y vitaminas.",
                    "Ayuda al control de la presión arterial.",
                    "Previene infecciones."
                ],
                benefit2Detail: "La falta de dependencia de hospitalización y equipos médicos específicos brinda la opción de:",
                benefit2DetailPoints: [
                    "Acoplamiento a las necesidades del paciente.",
                    "El procedimiento de DP promueve un bienestar lo cual ayuda a un retorno a las actividades del día a día.",
                    "Mayor tiempo con sus familiares, amigos y conocidos.",
                    "Disposición de tiempo para uno mismo y actividades personales o laborales."
                ],
                benefit3Detail: "El procedimiento de DP tiene la factibilidad de poder acoplarse a todas las necesidades y poder individualizarse en función a las circunstancias. Estas circunstancias comprenden principalmente los estilos de vida y personales de cada uno. En PDH optimizamos el tratamiento en relación a lo que el usuario solicite.",
                benefit4Detail: "Una dependencia a la hospitalización por parte de los pacientes en Diálisis Peritoneal puede suscitar:",
                benefit4DetailPoints: [
                    "Aumentos en los índices de infección en el orificio de salida.",
                    "Aumento en el número de infecciones al año por paciente.",
                    "Desmotivación de los pacientes a continuar con el procedimiento de DP.",
                    "Nuestro compromiso con el paciente es reducir el número de visitas promoviendo la DP en el hogar."
                ],
                benefit5Detail: "El hogar es un lugar muy importante para el desarrollo de las actividades básicas de una persona, ya que en él pueden sentirse bien, tranquilas, protegidas y seguras. El objetivo primordial de la DP es que el paciente pueda solicitar el tratamiento para que se realice en su casa. Uno de los puntos clave en el proceso es que se adecua a las necesidades y/o actividades que el paciente requiera, promoviendo así el bienestar y confort.",
                testimonial1: "Excelente servicio y atención. Me han ayudado a mejorar mi calidad de vida significativamente.",
                testimonial2: "Muy profesionales y atentos. Recomiendo ampliamente sus servicios.",
                testimonial3: "Altamente recomendados. El personal es muy capacitado y amable.",
                testimonial4: "Gracias a su servicio, he podido llevar mi tratamiento desde casa sin problemas.",
                testimonial5: "El mejor servicio de diálisis que he recibido. Siempre pendientes de mi bienestar.",
                videoFallback: "Tu navegador no soporta el elemento de video.",
                faqTitle: "Preguntas Más Frecuentes",
                faq1Question: "¿Si no tengo quien me dialice en casa, cómo hago?",
                faq1Answer: "Póngase en contacto con nuestros asesores clínicos para poder dar respuesta a los números de contacto.",
                faq2Question: "¿Si no tengo catéter, a dónde debo acudir?",
                faq2Answer: "Póngase en contacto con nuestros asesores clínicos para poder brindar toda la información necesaria.",
                faq3Question: "Soy viajero, ¿ya tengo catéter de diálisis, puedo llegar a El Salvador y que me provean el tratamiento y una enfermera?",
                faq3Answer: "Con mucho gusto se puede proveer todo lo necesario para realizar el tratamiento. Contamos con personal certificado.",
                faq4Question: "Vivo lejos, tengo material en casa y mi cuidadora no me puede dializar, ¿puedo pedir apoyo para que me dialice una enfermera?",
                faq4Answer: "Con mucho gusto llegamos a su hogar para realizar procedimientos con protocolos adecuados.",
                faq5Question: "Soy paciente viajero y no tengo quien me llegue a traer al aeropuerto para poderme dializar.",
                faq5Answer: "Nosotros podemos llegar al aeropuerto proporcionando vehículo y tratamiento para donde se hospede, todo con personal clínico especializado."
            },
            services: {
                title: "Nuestros Servicios",
                button: "Conocer Más",
                foreignService: "Servicio para Extranjeros",
                homeDialysisService: "Servicio de Diálisis en Domicilio del Paciente",
                materialService: "Servicio de Diálisis con Material pero sin Enfermera",
                clinicService: "Servicio de Diálisis en Clínica",
                elderCareService: "Cuidados del Adulto Mayor en el Hogar del Paciente",
                foreignServiceDescription: [
                    "Se ofrece transporte para ir a traer al aeropuerto",
                    "Enfermera certificada",
                    "Material de diálisis completo",
                    "Servicio completo de tratamiento",
                    "Se ajusta a horario flexible al paciente"
                ],
                homeDialysisServiceDescription: [
                    "Si el paciente no posee material",
                    "Se asigna una enfermera",
                    "Se proporcionan los materiales",
                    "Se brindan cuidados"
                ],
                materialServiceDescription: [
                    "Se ofrece enfermera certificada para realizar tratamiento",
                    "Se puede dializar en horario conveniente al paciente",
                    "Se puede dializar en casa del paciente"
                ],
                clinicServiceDescription: [
                    "Enfermera con certificación en diálisis peritoneal",
                    "Insumos de terapia",
                    "Control de presión arterial",
                    "Manejo de líquidos",
                    "Manejo de dieta",
                    "Monitoreo de signos vitales",
                    "Realización de la terapia de acuerdo al tiempo y necesidad de cada paciente"
                ],
                elderCareServiceDescription: [
                    "Se proporciona cuidado del adulto",
                    "Asistencia de medicamentos",
                    "Asistencia al adulto mayor en todas sus salidas",
                    "Se proporciona vehículo"
                ]
            },
            footer: {
                contact: "Contacto",
                nationalPhone: "Teléfono Nacional",
                internationalPhone: "Teléfono Internacional",
                hours: "Horario de Atención",
                weekdays: "Lunes a Viernes",
                saturday: "Sábado",
                sunday: "Domingo",
                closed: "Cerrado",
                socialMedia: "Síguenos",
                copyright: "Diálisis Peritoneal. Todos los derechos reservados."
            },
            about: {
                missionTitle: "Misión",
                missionText: "Realizar un tratamiento de Diálisis de calidad con equipo capacitado y certificado en tratamiento desde cuidados mínimos hasta la realización de cuidados de pacientes renales.",
                visionTitle: "Visión",
                visionText: "Proporcionar Diálisis y cuidados a pacientes tanto del país como pacientes del extranjero aplicando protocolos que permitan la seguridad en el tratamiento con personal de Enfermería y médicos especializados.",
                teamTitle: "El equipo médico",
                techTitle: "Tecnologías y Tratamientos",
                techItem1Title: "Bolsa Dianeal",
                techItem1Description: "Dentro de una bolsa dianeal contiene una solución que retiene los líquidos que normalmente eliminan los riñones. Los riñones son los responsables de eliminar el exceso de líquidos, minerales y desechos del cuerpo. Pero, cuando se produce una insuficiencia renal, estos desechos y otros líquidos pueden acumularse en el cuerpo, lo que provoca presión arterial alta y una disminución de los glóbulos rojos. Una bolsa de diálisis se puede utilizar como sustituto de un riñón durante una insuficiencia renal o después de un trasplante de riñón para permitir un estilo de vida productivo.",
                techItem2Title: "Catéter Tenckhoff en forma de cola de cochino",
                techItem2Description: "Un catéter Tenckhoff es un tubo delgado y flexible con terminación enrollada que asemeja a la cola de un cochinillo o cerdo por el cual se introduce líquido dializante al cuerpo y posteriormente se extrae de él. El tejido que cubre la mayor parte de los órganos del abdomen se llama peritoneo, y actúa como filtro dentro de este proceso DP.",
                techItem3Title: "Línea de Transferencia Corta de Larga Vida",
                techItem3Description: "Equipo de transferencia con sistema de desconexión consta de un conector hembra, una pinza de seguridad integrada, una tubería y un conector Luer Lock macho de doble sello para unirse a un conector de titanio el cual tiene una vida útil de aproximadamente 6 meses, este permite la conexión entre la bolsa dianeal y utiliza como seguro el tapón minicap.",
                techItem4Title: "Pinzas de Sujeción",
                techItem4Description: "Las Pinzas de Sujeción de la línea BAXTER tienen como única función obstrucción de la línea de drenaje, infusión y ocluye las líneas en el momento que se requiera sin necesidad de cortar o anudar ninguna línea.",
                techItem5Title: "Tapón Minicap",
                techItem5Description: "Es un tapón protector de plástico que contiene en su interior una esponja humedecida con yodopovidona evitando la contaminación de manera directa durante la conexión del paciente. Su efectividad es logrado a que provee una dosis de desinfectante necesaria para cada cambio de bolsa en DP.",
                techItem6Title: "Conector de Titanio",
                techItem6Description: "Es un dispositivo necesario para ajustar la punta final del catéter tipo Tenckhoff y la línea de transferencia. Sus características principales se encuentran en su capacidad de ser esterilizado, capacidad de soportar manipulaciones de técnicas asépticas, reutilizable en caso de ser necesario, libre de patógenos y su función más primordial en ser Atoxico y Antioxidante brindando bienestar en el paciente de DP."
            }
        }
    }
};

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        lng: 'es',
        fallbackLng: 'es',
        debug: true,
        detection: {
            order: ['cookie', 'localStorage', 'path', 'subdomain'],
            caches: ['cookie'],
        },
        react: {
            useSuspense: false,
        },
    });

export default i18n;
